<template>
  <div>
    <vxe-table
      ref="vxe_table"
      align="center"
      border
      round
      :data="tableData"
      style="width: 100%"
      :tree-config="treeConfig"
      :checkbox-config="checkboxConfig"
      :row-config="{ keyField: keyField }"
      :column-config="{ resizable: true }"
    >
      <template v-for="(item, index) in tableSet">
        <template v-if="!item.hidden">
          <vxe-column
            :key="index"
            v-if="item.scope === 'selection'"
            type="checkbox"
            width="70"
          ></vxe-column>
          <vxe-column
            v-else-if="item.scope === 'radio'"
            :key="index"
            type="radio"
            width="70"
          ></vxe-column>
          <vxe-column
            v-else
            :title="item.label"
            :key="index"
            :width="item.width"
            #default="{ row }"
            :tree-node="item.treeNode ? true : false"
          >
            <template v-if="item.scope == 'time'">
              <span>{{ $utils.onlyForma(row[item.prop], true, false) }}</span>
              <span v-if="item.prop1">
                至
                {{ $utils.onlyForma(row[item.prop1], true, false) }}</span
              >
            </template>
            <template v-else-if="item.scope == 'img'">
              <el-image
                v-if="row[item.prop]"
                style="width: 100px"
                :src="row[item.prop]"
                :preview-src-list="[row[item.prop]]"
              >
              </el-image>
            </template>
            <template v-else-if="item.scope === 'options'">
              <template v-for="(i, k) in item.options">
                <span v-if="i.value === row[item.prop]" :key="k">{{
                  i.label
                }}</span>
              </template>
            </template>
            <template v-else-if="item.scope === 'slot'">
              <slot :name="item.slotName" :row="row"></slot>
            </template>
            <span
              v-else-if="item.scope === 'moreProp'"
              :style="item.style || null"
              >{{ returnStr(item.prop, row) }}</span
            >
            <span v-else :style="item.style || null">{{ row[item.prop] }}</span>
          </vxe-column>
        </template></template
      >
      <vxe-column
        v-if="fixedStatus"
        title="操作"
        fixed="right"
        :width="fixedWidth"
      >
        <template #default="{ row }">
          <slot name="btn" :row="row"></slot>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
export default {
  props: {
    //唯一ID
    keyField: {
      type: String,
      default: "id",
    },
    //表格列设置
    tableSet: {
      type: Array,
      default: () => [],
    },
    //表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    //操作栏显示
    fixedStatus: {
      type: Boolean,
      default: true,
    },
    //操作栏宽度
    fixedWidth: {
      type: Number,
      default: 120,
    },
    treeConfig: {
      type: Object,
      default: () => {
        return { transform: false, rowField: "id", parentField: "parentId" };
      },
    },
    checkboxConfig: {
      type: Object,
      default: () => {
        return { highlight: true, reserve: false };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    search() {
      this.$emit("search");
    },
    returnStr(array, item) {
      if (!Array.isArray(array)) return;
      for (let i = 0; i < array.length; i++) {
        if (item[array[i]]) {
          return item[array[i]];
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .row--level-1 {
  background-color: rgba(239, 239, 239, 0.868);
}
</style>
