<template>
  <el-container class="main_box">
    <el-aside
      class="el_aside"
      :style="{ width: isCollapse ? '64px!important' : '200px!important' }"
    >
      <div class="logo_box">
        <img src="@/assets/images/logo.jpg" alt="" />
        <span v-if="!isCollapse">吉店宝</span>
      </div>
      <el-menu
        :collapse-transition="false"
        class="el-menu-vertical-demo"
        router
        unique-opened
        :default-active="activeRouter"
        :collapse="isCollapse"
        ><template v-for="(item, index) in children">
          <el-submenu
            v-if="
              !item.meta.hidden && item.children && item.children.length > 0
            "
            :key="index"
            :index="item.path"
          >
            <template slot="title">
              <i :class="item.meta.icon"></i>
              <span slot="title">{{ item.meta.title }}</span>
            </template>
            <template v-for="(items, indexs) in item.children">
              <el-menu-item
                v-if="!items.meta.hidden"
                :key="indexs"
                :index="item.path + '/' + items.path"
                >{{ items.meta.title }}</el-menu-item
              >
            </template>
          </el-submenu>
          <el-menu-item
            v-else-if="!item.meta.hidden"
            :key="index"
            :index="item.path"
          >
            <el-icon :class="item.meta.icon"></el-icon>
            <span>{{ item.meta.title }}</span>
          </el-menu-item>
        </template></el-menu
      >
      <i
        class="fd"
        :class="isCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
        @click="isCollapseFunc"
      ></i>
    </el-aside>
    <el-container>
      <el-header class="header">
        <div class="topHeader">
          <el-avatar
            icon="el-icon-user-solid"
            style="margin-right: 10px"
          ></el-avatar>
          <el-button type="text" style="margin-right: 10px; color: #909399">{{
            userInfo.nickname
          }}</el-button>
          <el-button @click="loginOut" type="text">退出</el-button>
        </div>
        <menu-tabs :activeRouter.sync="activeRouter" />
      </el-header>
      <el-main>
        <!-- <transition name="el-fade-in-linear"> -->
        <!-- v-slot="{ Component }"↓ -->
        <router-view class="router_view"></router-view>
        <!-- <keep-alive>
                <component :is="Component" v-if="$route.meta.keepAlive" />
              </keep-alive> -->
        <!-- <component
                :is="Component"
                v-if="!$route.meta.keepAlive"
              />  -->
        <!-- </transition> -->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import menuTabs from "./menuTabs.vue";
import { mapGetters } from "vuex";
import { children } from "@/router/index";
import { logout } from "@/api/user";
export default {
  components: { menuTabs },
  data() {
    return {
      children: children,
      activeRouter: "",
    };
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (!newVal.meta.hidden) {
          this.$store.commit("addTabs", {
            path: newVal.path,
            title: newVal.meta.title,
            canClose: true,
          });
        }
        this.activeRouter = newVal.path;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: { ...mapGetters(["isCollapse", "userInfo"]) },
  mounted() {
    // 监听屏幕宽度和高度变化
    window.addEventListener("resize", this.handleResize, true);
  },
  // 销毁监听
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 1200) {
        this.$store.commit("isCollapse", true);
      }
    },
    isCollapseFunc() {
      this.$store.commit("isCollapse");
    },
    //退出登录
    loginOut() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logout().then((res) => {
            this.$store.commit("loginOut");
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  user-select: none;
  height: 100px !important;
  & > .topHeader {
    padding: 0px 20px;
    height: 60px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.main_box {
  height: 100%;
  .el_aside {
    transition: all 0.06s;
    overflow: visible;
    position: relative;
    user-select: none;
    width: 200px !important;
    height: 100%;
    box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    & > .logo_box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      border-bottom: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;
      & > img {
        width: 40px;
        height: 40px;
        border-radius: 20%;
      }
      & > span {
        margin-left: 12px;
        font-size: 22px;
      }
    }
    & > .el-menu-vertical-demo {
      height: 1px;
      flex: 1;
    }
    .fd {
      cursor: pointer;
      position: absolute;
      top: 40%;
      right: -10px;
      height: 50px;
      background: rgb(226, 226, 226);
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
      width: 20px;
      border-radius: 100px;
      line-height: 50px;
      text-align: center;
      transition: all 0.2s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.el-header {
  //   box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  padding: 0px;
}
.router_view {
  padding: 0px;
}
</style>
