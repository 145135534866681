import Vue from 'vue'
import Vuex from 'vuex'
import { removeToken } from '@/utils/auth'
import { getInfo } from "@/api/user"
import router from '@/router'
import Cookies from 'js-cookie'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    userInfo: {},//用户信息
    isCollapse: Cookies.get('isCollapse') && Cookies.get('isCollapse') === 'true' ? true : false,
    tabsList: [{
      path: "/home",
      title: "首页"
    }]
  },
  getters: {
    userInfo: (state) => {
      if (Object.keys(state.userInfo).length === 0) {
        new Promise((resolve, reject) => {
          getInfo().then(res => {
            resolve(res)
          })
        }).then(res => {
          state.userInfo = res.data
        })
      }
      return state.userInfo

    },
    isCollapse: (state) => state.isCollapse,
    tabsList: (state) => state.tabsList,
  },
  mutations: {
    isCollapse(state, data = false) {
      if (data) {
        state.isCollapse = true
      } else {
        state.isCollapse = !state.isCollapse
      }
      Cookies.set('isCollapse', state.isCollapse)
    },
    /**
     * 
     * @param {*} state 退出系统
     */
    loginOut(state) {
      removeToken()
      window.location.replace('/login');
    },
    //新增tabs
    addTabs(state, data) {
      if (!state.tabsList.find(i => i.path === data.path)) {
        state.tabsList.push(data)
      }
    },
    //关闭tabs
    closeTabs(state, path) {
      if (path === '/home') return
      let index = null
      let tabsList = state.tabsList.filter((i, k) => {
        if (i.path === path) {
          index = k
        }
        return i.path !== path
      })
      if (router.currentRoute.path == path) {
        if (tabsList[index]) {
          router.push({ path: tabsList[index].path })
        } else {
          router.push({ path: tabsList[tabsList.length - 1].path })
        }
      }
      state.tabsList = tabsList
    },
    //设置用户信息
    SET_USERINFO(state, data) {
      state.userInfo = data
    }
  },
  actions: {
    // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(result => {
          const res = result.data
          commit('SET_USERINFO', res)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  modules: {}
})
