<template>
  <el-pagination
    @size-change="pageSizeChange"
    @current-change="pageNumChange"
    :current-page="pageNum"
    :page-sizes="[5, 10, 20, 50, 100, 200]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    style="text-align: center;margin-top: 14px;"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    //总条数
    total: {
      type: Number,
      default: 0,
    },
    //当前所在页码
    pageNum: {
      type: Number,
      default: 1,
    },
    //当前页条数
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {};
  },
  methods: {
    pageSizeChange(val) {
      this.$emit("update:pageNum", 1);
      this.$emit("update:pageSize", val);
      this.$emit("pageChange");
    },
    pageNumChange(val) {
      this.$emit("update:pageNum", val);
      this.$emit("pageChange");
    },
  },
};
</script>

<style lang="less" scoped>
</style>
