import request from '@/utils/request'

// 快手code置换access_token
export function kuaishouCode(data) {
    return request({
        url: '/kuaishou/getCode/' + data,
        method: 'get',
        dontShowToast: true
    })
}
// 快手获取WEB端网页授权地址
export function kuaishouPcAuthUrl(data) {
    return request({
        url: '/kuaishou/pcAuthUrl',
        method: 'get',
        params: data,
    })
}
