import request from '@/utils/request'

// 账号登录
export function login(data) {
    return request({
        url: '/auth/common/account_login',
        method: 'post',
        headers: {
            isToken: false
        },
        data: data,
    })
}
// 账号退出登录
export function logout(data) {
    return request({
        url: '/user/logout',
        method: 'post',
        data: data,
    })
}
// 获取登录用户信息
export function getInfo(data) {
    return request({
        url: '/user/getInfo',
        method: 'get',
        params: data,
    })
}
// 用户注册
export function registerUser(data) {
    return request({
        url: '/auth/common/user/registerUser',
        method: 'post',
        headers: {
            isToken: false
        },
        data: data,
    })
}
// 注册短信
export function smsRegister(data) {
    return request({
        url: '/auth/common/sms/register',
        method: 'post',
        headers: {
            isToken: false
        },
        data: data,
    })
}
// 获取验证码
export function getCode(data) {
    return request({
        url: '/code',
        method: 'get',
        params: data,
        headers: {
            isToken: false
        },
        noPrefix: true
    })
}