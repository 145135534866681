<template>
  <el-tabs
    :value="activeRouter"
    type="card"
    @tab-remove="removeTab"
    :before-leave="beforeFunc"
  >
    <el-tab-pane
      v-for="item in tabsList"
      :closable="item.canClose ? true : false"
      :key="item.path"
      :label="item.title"
      :name="item.path"
    ></el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    activeRouter: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["tabsList"]),
  },
  methods: {
    beforeFunc(newRouterPath) {
      this.$router.push({ path: newRouterPath });
      return true;
    },
    removeTab(targetName) {
      this.$store.commit("closeTabs", targetName);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs__header {
  margin-bottom: 0px !important;
}
/deep/ .el-tabs__nav-next,
.el-tabs__nav-prev {
  line-height: 40px;
}
/deep/ .el-tabs__nav {
  border-top: none !important;
}
</style>
