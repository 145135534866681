<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="handleClose"
  >
    <slot></slot>
    <span slot="footer" class="dialog-footer" v-if="footerBtn">
      <slot name="btnSlot"></slot>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">{{ submitName }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "提示",
    },
    width: {
      type: String,
      default: "600px",
    },
    submitName: {
      type: String,
      default: "确 定",
    },
    footerBtn: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    dialogVisible(newVal, oldVal) {
      if (newVal) {
        this.$emit("openBoxs");
      } else {
        this.$emit("closeBoxs");
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    close() {
      this.$emit("update:dialogVisible", false);
    },
    handleClose() {
      this.close();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  border-radius: 10px;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #eee;
}
/deep/ .el-dialog__footer {
  border-top: 1px solid #eee;
}
/deep/ .el-dialog__title {
  font-size: 16px;
}
</style>
