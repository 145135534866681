import router from '@/router'
import store from "@/store"
import Cookies from 'js-cookie'
import { Message } from 'element-ui'
import { kuaishouCode } from '@/api/kuaishou'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })
router.beforeEach(async (to, from, next) => {
    NProgress.start();
    if (Cookies.get("access_token")) {
        if (to.path === '/login') {
            next({ path: '/' })
        } else if (to.name === 'callback') {
            if (to.query && to.query.code && to.params && to.params.id) {
                try {
                    if (to.params.id == 'kuaishou') {
                        var data = await kuaishouCode(to.query.code)
                        Success(data.msg, 2)
                    } else if (to.params.id == 'douyin') {
                        // 请求接口
                    } else {
                        throw "平台码识别错误"//抛出错误
                    }
                    next({ path: '/authorization/shopManage' })
                } catch (error) {
                    Error(error)
                    next({ path: '/' })
                }
            } else {
                Error('授权参数缺失')
                next({ path: '/' })
            }
        } else {
            next()
        }
    } else {
        if (to.path === '/login') {
            next()
        } else {
            next({ path: '/login' })
        }
    }
});
router.afterEach(() => {
    NProgress.done()
})
function Success(msg, type) {
    sessionStorage.setItem('storeOpenId', msg)
    sessionStorage.setItem('storeFromPlat', type)
    Message({
        message: '授权成功',
        type: 'success',
        duration: 2000,
    })
}
function Error(error = '') {
    sessionStorage.removeItem('storeFromPlat')
    sessionStorage.removeItem('storeOpenId')
    Message({
        message: error || '授权失败',
        type: 'error',
        duration: 5000,
        showClose: true
    })
}