import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/index.css"
import "./permission"
import Cookies from 'js-cookie'
import Element from 'element-ui'
import './assets/fonts/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css';
import Search from "@/components/t-search"
import Table from "@/components/t-table"
import Pagination from "@/components/t-pagination"
import Dialog from "@/components/t-dialog"
import utils from '@/utils/index'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
// import { hiPrintPlugin } from 'vue-plugin-hiprint'
// Vue.use(hiPrintPlugin, '$pluginName')
// import jquery from 'jquery'
// Vue.prototype.$ = jquery 

import WebSocketPlugin from './utils/websocket';
// 对服务端进行websocket的连接
// WebSocketPlugin.Instance.connect()

Vue.prototype.$socket = WebSocketPlugin.Instance


Vue.config.productionTip = false

Vue.component('t-search', Search)
Vue.component('t-table', Table)
Vue.component('t-pagination', Pagination)
Vue.component('t-dialog', Dialog)

Vue.prototype.$utils = utils
Vue.use(VXETable)
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
